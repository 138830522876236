import React, { useEffect, useState } from "react";
import {
  DivItemStockEntry,
  FormItemStockEntry,
  DivOrgItemStockEntry,
  LabelItemStockEntry,
  InputBig,
  InputSmall,
  InputDate,
  LabelResult,
  LabelCalcResult,
  SelectOption,
  Options,
  SubmitFormItemStockEntry,
  DivOrgResults,
  InfoResults,
  DivOrgInput,
  DivOrgScreen,
  DivAlerts,
  Alerts,
  TitleAlert,
  TitleDelivery,
  SelectProvider,
  SelectProduct,
} from "./NewItemStyle";
import TableItem from "../../Tables/TableItem/TableItem";

import { useDebounce } from "use-debounce";

import { useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";

export default function NewItem(props) {
  const listProviders = props.providersData.provider;
  const listProducts = props.productsInfo.product;

  const [priceUnitFormated, setPriceUnitFormated] = useState("");
  const [productStock, setProductStock] = useState([]);
  const [productId, setProductId] = useState(0);
  const [productInfo, setProductInfo] = useState({});
  const [productSearch] = useDebounce(productId, 300);
  const [valueDelivery, setValueDelivery] = useState(0);
  const [infoQtd, setInfoQtd] = useState(0);
  const [batchOption, setBatchOption] = useState();

  // const [inputData, setInputData] = useState("");
  const [inputDelivery, setInputDelivery] = useState(0);

  const [isClearable] = useState(true);
  const [provider, setProvider] = useState([]);
  const [optionsProviders, setOptionsProviders] = useState([]);

  const [product, setProduct] = useState([]);
  const [optionsProducts, setOptionsProducts] = useState([]);

  const { register, handleSubmit, watch, setValue, reset, formState } = useForm(
    {
      defaultValues: {
        priceTotal: 0,
      },
    }
  );

  // Aqui faz o envio de um ojbeto e retorna o valor inicial
  function sendProductToStock(dataProduct) {
    // envia o valor unitario do item
    dataProduct.priceUnit = Number(priceUnitFormated);
    // enviar o id do produto
    dataProduct.idProduct = product.value;
    // enviar o id do fornecedor
    dataProduct.idProvider = provider.value;
    // envia o valor do delivery
    dataProduct.valueDelivery = Number(valueDelivery);
    //Envia o nome do produto
    dataProduct.nameProduct = product.label;
    // coloca a opção de validade
    dataProduct.dueDateOption = batchOption;
    //Envia a quantidade
    dataProduct.newQtd = Number(infoQtd);

    if (dataProduct.productBatch === "") {
      delete dataProduct.productBatch;
      dataProduct.dueDateProduct = "sem vencimento";
    }
    setProductStock(dataProduct);
  }

  const newQtd = Number(infoQtd);
  const priceUnit = Number(priceUnitFormated);

  const disableScroll = (e) => {
    e.target.addEventListener(
      "wheel",
      function (e) {
        e.preventDefault();
      },
      { passive: false }
    );
  };

  const sendProvider = (provider) => {
    setProvider(provider);
  };
  const sendProduct = (product) => {
    setProduct(product);
  };

  useEffect(() => {
    let totalPrice;

    if (newQtd > 0) {
      totalPrice = newQtd * priceUnit;
      if (totalPrice) {
        setValue("priceTotal", Number(totalPrice));
      } else {
        setValue("priceTotal", 0);
      }
    }
    // getProductInfo(productSearch)
  }, [newQtd, priceUnit, setValue]);

  useEffect(() => {
    if (productSearch.length === 0) {
      setProductInfo(productSearch);
      setBatchOption();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSearch]);

  useEffect(() => {
    if (productInfo.codeStatus === 200) {
      setBatchOption(productInfo.product.dueDateOption);
    }
  }, [productInfo]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      setTimeout(() => {
        reset();
        setPriceUnitFormated("");
        setBatchOption();
        setProductInfo([]);
        setProductId(0);
      }, 1500);
    }
  }, [formState, reset]);

  useEffect(() => {
    if (listProviders?.length > 0) {
      const optionsProviders = listProviders.map((providers) => ({
        value: providers.idProvider,
        label: providers.nameProvider,
      }));
      setOptionsProviders(optionsProviders);
    }
    if (listProducts?.length > 0) {
      const optionsProducts = listProducts.map((product) => ({
        value: product.idProduct,
        label: product.nameProduct,
      }));
      setOptionsProducts(optionsProducts);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listProducts]);

  return (
    <DivOrgScreen>
      <DivItemStockEntry>
        <FormItemStockEntry onSubmit={handleSubmit(sendProductToStock)}>
          <DivOrgItemStockEntry>
            <LabelItemStockEntry>Nome do Produto</LabelItemStockEntry>
            <SelectProduct
              name="product"
              placeholder="Selecione"
              options={optionsProducts}
              isClearable={isClearable}
              onChange={sendProduct}
            />
          </DivOrgItemStockEntry>
          <DivOrgItemStockEntry>
            <DivOrgInput>
              <LabelItemStockEntry>Quantidade</LabelItemStockEntry>
              <InputSmall
                // disabled={inputQtd}
                type="number"
                step="any"
                onChange={(e) => {
                  // limitQtd(e.target.value);
                  setInfoQtd(e.target.value);
                }}
                onFocus={disableScroll}
              />
            </DivOrgInput>
          </DivOrgItemStockEntry>
          <DivOrgItemStockEntry>
            <DivOrgInput>
              {/* colocar para utilizar valores em decimal e formatar R$00.000.00*/}
              <LabelItemStockEntry>Preço Unitario</LabelItemStockEntry>
              <NumericFormat
                value={priceUnitFormated}
                customInput={InputSmall}
                placeholder="R$ "
                allowEmptyFormatting
                mask="_"
                decimalSeparator=","
                fixedDecimalScale
                thousandSeparator="."
                decimalScale={2}
                prefix={"R$"}
                onValueChange={(values, sourceInfo) => {
                  setPriceUnitFormated(Number(values.value));
                }}
              />
            </DivOrgInput>
            <DivOrgInput>
              <LabelItemStockEntry>Preço Total</LabelItemStockEntry>
              {/* Label do calc de preço unit x newQtd e formatar para 
              var f = atual.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});*/}
              {/* <LabelCalcResult>R$ {watch("priceTotal" || 0)}</LabelCalcResult> */}
              <NumericFormat
                customInput={LabelCalcResult}
                displayType="text"
                value={watch("priceTotal")}
                decimalSeparator=","
                thousandSeparator="."
                fixedDecimalScale
                decimalScale={2}
                prefix={"R$ "}
              />
            </DivOrgInput>
          </DivOrgItemStockEntry>
          <DivOrgItemStockEntry>
            <LabelItemStockEntry>Fornecedor</LabelItemStockEntry>
            <SelectProvider
              name="provider"
              placeholder="Selecione"
              options={optionsProviders}
              isClearable={isClearable}
              onChange={sendProvider}
            />
          </DivOrgItemStockEntry>
          <TitleDelivery>Valor do Frete</TitleDelivery>
          <DivOrgItemStockEntry>
            <DivOrgInput>
              <LabelItemStockEntry>Frete</LabelItemStockEntry>
              <SelectOption
                {...register("statusDelivery", {
                  onChange: (e) => {
                    setInputDelivery(e.target.value);
                  },
                })}
              >
                <Options value="">Selecionar</Options>
                <Options value="sim">Sim</Options>
                <Options value="nao">Não</Options>
              </SelectOption>
            </DivOrgInput>
            {inputDelivery === "sim" && (
              <DivOrgInput>
                <LabelItemStockEntry>Valor</LabelItemStockEntry>
                <NumericFormat
                  placeholder="R$ "
                  customInput={InputSmall}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale
                  decimalScale={2}
                  prefix={"R$"}
                  onValueChange={(values, sourceInfo) => {
                    setValueDelivery(Number(values.value));
                  }}
                />
              </DivOrgInput>
            )}
          </DivOrgItemStockEntry>
          <SubmitFormItemStockEntry type="submit">
            Adicionar
          </SubmitFormItemStockEntry>
        </FormItemStockEntry>
      </DivItemStockEntry>
      {/* Passa um objeto, resetar os inputs apos enviar */}
      <TableItem productStock={productStock} />
    </DivOrgScreen>
  );
}
