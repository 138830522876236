import React from "react";
import {
  DivComercial,
  DivOrgComercial,
  TitleComercial,
} from "./CommercialStyle";

import Order from "../../components/Forms/Order/Order";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { allProducts } from "../../store/registers/products/products.actions";
import { useState } from "react";
import { useEffect } from "react";

export default function Commercial() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [listProduct, setListProduct] = useState([]);
  let { orderChange } = 0;
  orderChange = location.state || {};
  // ! Por em quanto não achei uma solução melhor para limpar a lista quando for alterar - 05/07/2023
  window.history.replaceState(orderChange, null);
  const infoOrderChange = orderChange;

  const getProductList = async () => {
    const listProducts = await dispatch(allProducts());
    setListProduct(listProducts.payload);
  };

  useEffect(() => {
    getProductList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fazer que a chamada inicie aqui e a order envie para ca
  return (
    <DivComercial>
      <TitleComercial>Comercial</TitleComercial>
      <DivOrgComercial>
        <Order
          infoOrderChange={infoOrderChange.orderChange}
          listProduct={listProduct?.product}
        />
      </DivOrgComercial>
    </DivComercial>
  );
}
